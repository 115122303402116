<script>
	import { UserEmail } from '$lib/store.js';
	import hash from 'object-hash';

	export let roboString = hash($UserEmail ? $UserEmail : 'default@magicflow.com');
</script>

<img
	class="h-5 w-5 rounded-full"
	src={`https://robohash.org/${roboString}`}
	onerror="this.onerror=null;this.src='bg.webp';"
	alt="avatar"
/>
