<script>
	import { serverURL, post_sugar, get_sugar } from '$lib/utils.js';
	import { onMount } from 'svelte';
	import { goto } from '$app/navigation';
	import { UserEmail, StripeInfo, Version } from '$lib/store.js';
	import dayjs from 'dayjs';
	import LoadingButton from '../components/LoadingButton.svelte';
	import LoadingSpan from '../components/Loading.svelte';
	import SubscriptionCards from '../components/SubscriptionCards.svelte';
	import { Path } from '$lib/store.js';
	export let show;
	let isLoading = false;
	let hasStripe = false;
	let subscriptions = [];

	// Set your publishable key: remember to change this to your live publishable key in production
	// See your keys here: https://dashboard.stripe.com/account/apikeys

	let isTest = 'test';

	let nicknames = {
		price_1HZbV8ImjdUjPf7UgdQagYN6: 'Test Founding',
		price_1H32KAImjdUjPf7UPoqla2We: 'Test Casual',
		price_1HZFd0ImjdUjPf7U8uyfRuOA: 'Test Pro',
		price_1HXrLCImjdUjPf7UQZsdabbE: 'Founding User',
		price_1HXQ4qImjdUjPf7UrflCOeOx: 'Early Believer',
		price_1H5AHPImjdUjPf7UsepCIdvc: 'Casual',
		price_1H4Q5QImjdUjPf7U9dkwVZzp: 'Pro'
	};

	$: if (
		$StripeInfo &&
		$StripeInfo.subscriptions &&
		$StripeInfo.subscriptions.data &&
		$StripeInfo.subscriptions.data?.length > 0
	) {
		subscriptions = $StripeInfo.subscriptions.data;
		console.log({ $StripeInfo });
		hasStripe = true;
	}

	// stripe = Stripe("pk_test_TYooMQauvdEDq54NiTphI7jx");
	async function loginHandler(event) {
		// Request 1,
		let actionURL = `${serverURL}/api/v1/oauth/stripe/portal/`;
		isLoading = true;
		console.log('going to request', actionURL);
		let res = await get_sugar(actionURL);
		isLoading = false;
		// Goto redirect URL
		if (res?.url) window.location = res?.url;
		else if (event.target)
			event.target.innerText = `Error, contact mike@${
				$Version?.app == 'omnipilot' ? 'omnipilot.ai' : 'magicflow.com'
			}`;
	}
	let yourplans;
	// $: console.log($Path);
</script>

<div
	id="page"
	class="p-4"
	style={$Path.startsWith('/profile/plans') || show
		? 'height:100%;'
		: 'opacity:0;position:absolute;bottom:100%;pointer-events:none;max-width:100vw;z-index:0;'}
>
	<h1 class="w-full text-center">Subscriptions and Billing</h1>
	<p class="text-sm font-medium">Pick a plan, or manage your subscriptions.</p>
	<div id="subPage" class="flex flex-col items-stretch gap-3">
		<div
			class="flex items-center bg-black p-4 bg-opacity-40 rounded-xl text-sm justify-between my-4"
		>
			<button
				class="{hasStripe
					? ''
					: 'bg-blue-200 text-black outline-gray-600 outline outline-offset-2'} px-4 py-1"
				on:click|preventDefault={() => (hasStripe = false)}
			>
				Subscription plans
			</button>
			<button
				class="{hasStripe
					? 'bg-blue-200 text-black outline-gray-600 outline outline-offset-2'
					: ''} px-4 py-1"
				on:click|preventDefault={() => (hasStripe = true)}
			>
				Your subscriptions
			</button>
		</div>
		{#if hasStripe}
			<div class="grid text-sm" id="billingPage">
				<div>
					<h2>Subscriptions</h2>
					{#if !subscriptions?.length}
						<p>You don't have a subscription!</p>
						<button on:click={() => (hasStripe = false)}> Fix that! </button>
					{:else}
						{#each subscriptions as s}
							<card class="border border-gray-800 rounded-2xl my-4">
								<div class="flex items-end justify-between mb-3">
									<p class="capitalize font-semibold text-lg">
										{nicknames[s?.plan?.id] ||
											($Version?.app == 'omnipilot' ? 'Omnipilot' : 'Founding User')}
									</p>
									<p>
										{!s.plan || s.plan?.currency?.toUpperCase() == 'USD' ? '$' : '£'}{(s.plan
											?.amount || 1900) / 100} / {s.plan?.interval || ' per month'}
									</p>
								</div>
								<div class="flex items-center justify-between">
									<p
										class="font-semibold px-2 rounded bg-opacity-90 {(s.plan || s)?.active
											? 'bg-green-600'
											: 'bg-red-600'}"
									>
										{(s.plan || s)?.active ? 'Active' : 'Inactive'}
									</p>
									<p>
										Subscribed {s.plan
											? 'since ' + dayjs(s.plan?.created * 1000).format('DD MMM YYYY')
											: ''}
									</p>
								</div>
							</card>
						{/each}
					{/if}
				</div>
				<div class="mb-8">
					<LoadingButton
						id="billing-portal"
						{isLoading}
						bclass={'bg-gray-200 text-black'}
						onClick={$Version.app === 'omnipilot'
							? () => window.open('https://billing.stripe.com/p/login/6oEcP169L2Lnd7a144', '_blank')
							: loginHandler}
						text="Manage your subscription"
					/>
				</div>
			</div>
		{:else}
			<SubscriptionCards {isTest} />
		{/if}
	</div>
</div>

<style>
	#page {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}
	#subPage {
		padding: 0;
		border-radius: 1vh;
		width: 100%;
		text-align: center;
		max-width: 600px;
		margin: 0 auto;
		min-height: 40vh;
		position: relative;
	}
	.componentNav {
		display: flex;
		width: 100%;
		margin: 0 0 2vh;
		padding: 1vh;
		background: #000000;
		border-radius: 500px;
	}

	#billingPage {
		grid-template-columns: 1.5fr 1fr;
		grid-gap: 4vh;
	}

	h2 {
		grid-column: 1/3;
		text-align: left;
	}
	/* .cols2 {
		border-radius: 8px;
		padding: 2vh;
		display: grid;
		gap: 2vh 0;
		display: grid;
		grid-template-columns: 1fr 1.5fr;
		grid-template-rows: auto;
		background-color: var(--card-background);
	} */
	div {
		text-align: left;
	}
	p,
	h1 {
		padding: 0 0.25rem;
	}
</style>
