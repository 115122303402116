<script>
	import RoboAvatar from '../components/RoboAvatar.svelte';
	import FeedbackButton from '../components/FeedbackButton.svelte';
	import Account from './Account.svelte';
	import DataChoices from './DataChoices.svelte';
	import Data from './Data.svelte';
	import Plans from './Plans.svelte';
	import Referrals from './Referrals.svelte';
	import { goto } from '$app/navigation';
	import { Path, IsElectron, Version, UserEmail } from '$lib/store.js';
	import { RenderDelayedContent } from '$lib/store.js';
	export let dropdown = false;
	function select(value) {
		return;
	}
	let selected = '';
	let checking;
	let quitting;
</script>

{#if !dropdown && ($RenderDelayedContent || $Path.includes('profile'))}
	<Account />
	<DataChoices />
	<Plans />
	<!-- <Referrals /> -->
{/if}
<section
	class="profilePage"
	style={dropdown || $Path === '/profile'
		? ''
		: 'opacity:0;position:absolute;bottom:100%;pointer-events:none;max-width:100vw;z-index:0;'}
>
	<div class="profileNav">
		<header id="title" class="profheader flex items-center">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke-width="1.5"
				stroke="currentColor"
				class="w-10 h-10"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495"
				/>
			</svg>
			<h2 class="m-0">Settings</h2>
		</header>
		<ul class="top-level-nav">
			<li>
				<a
					id="Account settings"
					href="/profile/account"
					on:click|preventDefault={() => goto('/profile/account')}
					class="proflink {selected == 'Account settings' ? 'selected' : ''}"
				>
					<RoboAvatar />
					<div>
						<div class="">Your Account</div>
						<div class="text-slate-400 font-medium text-xs">
							{$UserEmail || 'No email'}
						</div>
					</div>
				</a>
			</li>
			<!-- <li>
				<a
					id="Data"
					href="/data"
					on:click|preventDefault={() => goto() ;(('Data')}
					class="proflink {selected == 'Data' ? 'selected' : ''}"
				>
					<svg
						class="navLogo"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fill-rule="evenodd"
							d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
							clip-rule="evenodd"
						/>
					</svg>
					<div>Data Sources</div>
				</a>
			</li> -->
			<!-- <li>
				<a
					id="Data choices"
					href="/profile/data-choices"
					on:click|preventDefault={() => goto() ;(('Data choices')}
					class="proflink {selected == 'Data choices' ? 'selected' : ''}"
				>
					<svg
						class="navLogo"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fill-rule="evenodd"
							d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0
              0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8
              11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541
              3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414
              1.414l2 2a1 1 0 001.414 0l4-4z"
							clip-rule="evenodd"
						/>
					</svg>
					<div>Data Choices</div>
				</a>
			</li> -->
			<li>
				<a
					id="Subscriptions and Billing"
					href="/profile/plans"
					on:click|preventDefault={() => goto('/profile/plans')}
					class="proflink {selected == 'Subscriptions and Billing' ? 'selected' : ''}"
				>
					<svg
						class="navLogo"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
						<path
							fill-rule="evenodd"
							d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1
              0 110 2h2a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
							clip-rule="evenodd"
						/>
					</svg>
					<div>Plans & Billing</div>
				</a>
			</li>
			<!-- <li>
				<a
					id="Referrals"
					href="/profile/referrals"
					on:click|preventDefault={() => goto("/profile/referrals") }
					class="proflink {selected == 'Referrals' ? 'selected' : ''}"
				>
					<svg
						class="navLogo"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94
              2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0
              000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z"
						/>
					</svg>
					<div>Referrals</div>
				</a>
			</li> -->
			<li>
				<div
					class="proflink"
					on:click={() => {
						if (confirm('Are you sure you want to sign out?')) goto('/logout');
					}}
				>
					<svg
						class="navLogo"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0
          013-3h4a3 3 0 013 3v1"
						/>
					</svg>
					<div class="font-medium">Log out</div>
				</div>
			</li>
			{#if $IsElectron}
				<button
					class="w-10/12 my-4 py-3 mx-auto block text-white"
					on:click={() => {
						window.api?.call('checkForUpdates');
						checking = true;
						setTimeout(() => (checking = false), 60000);
					}}>{checking ? 'Checking... 2mins' : 'Check for Updates'}</button
				>
				{#if checking === false}
					<p>No update found. Auto-checks every hour.</p>
				{/if}
				<button
					class="w-10/12 mt-0 mb-4 py-3 mx-auto block bg-red-800 text-white"
					on:click={() => {
						window.api?.call('quit');
						quitting = true;
					}}>{quitting ? 'Quitting..' : 'Quit'}</button
				>
			{/if}
		</ul>
		<div id="feedbackholder">
			<FeedbackButton buttonText="Tell us!" category="datachoices" />
		</div>

		<div class="text-xs mb-1">
			Version: {$IsElectron
				? $Version?.version || $Version || process.env.VERSION
				: process.env.VERSION} - {typeof window !== 'undefined' &&
			window.location.protocol.includes('http')
				? 'LIVE'
				: 'OFFLINE'}
		</div>
	</div>
	<!-- <breadcrumb>User settings > {selected}</breadcrumb> -->
	<slot />
</section>

<style>
	.profilePage {
		padding: 0px;
		width: 100%;
		margin: 0 auto;
		align-items: center;
		justify-items: center;
		overflow: auto;
	}

	.profileNav {
		position: relative;
		border-radius: 0px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		height: 100%;
		width: 100%;
	}
	.top-level-nav {
		padding: 0px;
		margin: 0px;
		width: 100%;
	}
	.profheader,
	.proflink {
		width: 100%;
		margin: auto;
		height: 10vh;
		padding: 15px 15% 15px 25%;
		display: flex;
		gap: 20px;
		align-items: center;
		color: white;
	}
	.proflink {
		cursor: pointer;
		height: unset;
	}

	.proflink:hover {
		background-color: rgba(230, 230, 230, 0.2);
	}
	.proflink.selected {
		background-color: var(--card-background);
		color: var(--logo-lightblue);
	}
	.navLogo {
		width: 3vh;
		height: 3vh;
	}
	li {
		width: 100%;
	}
	#feedbackholder {
		width: 90%;
		margin-top: auto;
		margin-bottom: 2rem;
	}
</style>
