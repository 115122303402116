<script>
	import { UserEmail, UserToken } from '$lib/store.js';
	import { onMount } from 'svelte';
	import ListErrors from '../components/ListErrors.svelte';
	import Modal from '../components/Modal.svelte';
	import LoadingOrb from '../components/LoadingOrb.svelte';
	import { Path, Version } from '$lib/store.js';
	import {
		logout,
		getAuth,
		deleteUser,
		updatePassword,
		updateEmail
		// resetEncryption,
	} from '$lib/authapi.js';
	import { get } from 'svelte/store';
	export let show;
	let errors = [];
	let user = {};
	let isLoading;
	// Form elements
	let formEmail = '';
	let formNewPassword = '';
	let formNewPasswordConfirm = '';
	let formCurrentPassword = '';
	let formUsername = '';
	let encryption_key = 'Click to reveal.';
	let username = $UserEmail;
	let password;
	let deleting;
	async function deleteUserListener() {
		errors = [];
		isLoading = true;
		if (
			confirm(
				"Are you sure you want to delete your account? You'll also delete all your data and settings."
			)
		) {
			deleting = true;
			await deleteUser(
				$UserEmail,
				password,
				$UserToken?.firebaseData?.user?.providerData?.[0]?.providerId
			).catch((e) => (errors = [e]));
			if (errors?.length) return;
			deleteUserModal = false;
			deleting = false;
			setTimeout(() => {
				$UserEmail = false;
				alert(
					$Version.app === 'omnipilot'
						? "Thanks for trying Omnipilot. I'd love feedback on why you deleted your account, email mike@omnipilot.ai or find me on twitter @michaeljelly"
						: 'Thanks for trying Magicflow. We (Mike and Oscar) would love to hear why you deleted your account and how we can make Magicflow better. If that sounds good, mail hello@magicflow.com or find us on twitter @magicflowHQ (twitter.com/magicflowHQ) and we can organise a chat.'
				);
			}, 2000);
		} else
			alert(
				"Thanks for staying! You can delete any time (although we'd love to chat before you do)."
			);
	}

	// async function updateEmailListener() {;
	//   errors = [];
	//   await updateEmail(formEmail).catch((err) => {
	//     errors = [...(errors || []), err];
	//     if (err == "Need to reauthenticate to update email. Redirecting...") {
	//       alert(err);
	//       logout();
	//       goto("/login");
	//     }
	//   });
	//   user = await getAuth().catch((err) => (errors = [...(errors || []), err]));
	//   if (!Array.isArray(errors) || !errors?.length) {
	//     alert(`Email updated to ${formEmail}`);
	//     UserEmail.set(formEmail);
	//   }
	// }

	async function changePasswordListener() {
		errors = [];
		if (formNewPassword === formNewPasswordConfirm) {
			await updatePassword(formCurrentPassword, formNewPassword)
				.catch((err) => {
					errors = [...(errors || []), err];
				})
				.then(() => {
					alert('Password changed');
				});
		} else {
			errors = [...(errors || []), 'New passwords do not match.'];
		}
	}
	let deleteUserModal;
</script>

<div
	class="account w-[420px] p-10 bg-black bg-opacity-10 border border-gray-500 border-opacity-30 my-4 rounded-2xl"
	style={$Path.startsWith('/profile/account') || show
		? ''
		: 'opacity:0;position:absolute;bottom:100%;pointer-events:none;max-width:100vw;z-index:0;'}
>
	<h1>Account details</h1>

	{#if errors?.length > 0}
		<ListErrors {errors} />
	{/if}

	<label class="my-4" for="email-form">Email: {$UserEmail}</label>

	<form id="password" class="my-4" autocomplete="off">
		<h3 class="font-semibold">Change Password</h3>
		<input
			autofill="off"
			required
			id="current-password"
			bind:value={formCurrentPassword}
			type="password"
			placeholder="Current password"
		/>
		<br />
		<input
			autofill="off"
			required
			id="new-password"
			bind:value={formNewPassword}
			type="password"
			placeholder="New password"
		/>
		<input
			autofill="off"
			required
			id="confirm-password"
			bind:value={formNewPasswordConfirm}
			type="password"
			placeholder="New password"
		/>
		<br />
		<button type="submit" on:click|preventDefault={() => changePasswordListener()}>
			Change Password
		</button>
	</form>

	<form id="delete" on:submit|preventDefault={deleteUserListener}>
		<button class="bg-red-600 text-white" on:click|preventDefault={() => (deleteUserModal = true)}>
			DELETE ACCOUNT
		</button>
		<Modal bind:show={deleteUserModal}>
			<h2>You need to authenticate to delete your account.</h2>
			{#if $UserToken?.firebaseData?.user?.providerData?.[0]?.providerId === 'google.com'}
				<button
					class=" text-black w-full flex items-center justify-center gap-4 h-14 mx-auto text-xl rounded-xl"
					style="background: white !important;"
					on:click|preventDefault={deleteUserListener}
				>
					{#if isLoading}
						<span
							class="spinner-border spinner-border-sm"
							style="color:white"
							role="status"
							aria-hidden="true"
						/>
						<span class="sr-only">Loading...</span>...Authenticating
					{:else}
						<img src="/logos/google_logo.svg" style="height:1em;" alt="google logo" />
						<span> Sign in with Google</span>
					{/if}
				</button>
			{:else}
				<input
					id="password"
					class="p-4 bg-black bg-opacity-30"
					style="transition: all 0.4s"
					required
					autocomplete={'current-password'}
					type="password"
					placeholder={'YourPassword1!'}
					bind:value={password}
				/>
				<button
					disabled={!password}
					class="bg-red-600 text-white"
					on:click|preventDefault={deleteUserListener}
				>
					REALLY DELETE
				</button>
			{/if}
			{#if errors?.length > 0}
				<ListErrors {errors} />
			{/if}
			{#if deleting && !errors?.length}
				<LoadingOrb fullScreen={false} />
			{/if}
		</Modal>
	</form>
</div>

<style>
	.account {
		text-align: left;
		max-width: 600px;
		margin: 12px auto;
	}

	input {
		width: 100%;
		height: 100%;
		border-radius: 0.7vh;
		border-width: 0.1vh;
		padding: 1vh;
		margin: 0.5rem 0;
		border-style: solid;
		border-color: lightgray;
	}
	button {
		width: 100%;
	}
	#password {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
</style>
