<script>
	import { UserEmail, Version, IsDev, IsElectron, Platform, Path } from '$lib/store.js';
	import { goto } from '$app/navigation';
	import { fade } from 'svelte/transition';
	import dayjs from 'dayjs';
	let hovering;
	let timeout;
	// console.log('server here');
</script>

<a
	on:mouseenter={() => {
		console.log('entered');
		hovering = true;
		clearTimeout(timeout);
	}}
	on:mouseleave={(e) => {
		console.log('left', e);
		clearTimeout(timeout);

		timeout = setTimeout(() => (hovering = false), 500);
	}}
	class="rounded-full {$Path === '/onboard'
		? 'hidden'
		: ''} pl-3 pr-2 text-xs gap-1 relative text-white text-opacity-80 cursor-pointer flex items-center bg-transparent justify-end shadow-none p-1 m-1 ml-0"
	style="background:#1962cC;"
	on:click={(event) => {
		if ($IsDev) event.preventDefault();
		else return;
		if ($IsElectron) window.api?.call('loginPage');
		// if ($IsDev && $UserInfo.toursCompleted)
		// 	$UserInfo.toursCompleted = $UserInfo.toursCompleted.filter((a) => a !== $Path);
	}}
	href="{!$IsDev
		? $Version?.platform != 'darwin' || $Platform !== 'macos'
			? 'mailto:mike@' + ($Version.app === 'omnipilot' ? 'omnipilot.ai' : 'magicflow.com')
			: 'sms:+447576358357'
		: 'sms:+447576358357'}?&body={encodeURI(
		`Hey Mike, it's ${
			($UserEmail || '')?.replace('@', ' @ ') || 'a new user'
		}, I have feedback on Magicflow!&subject=Magicflow help request from ${
			($UserEmail || '')?.replace('@', ' @ ') + ' - ' + dayjs().format('YYYY-MM-DD')
		}`
	)}"
>
	Help
	<div class="relative">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			class="h-5 w-5 opacity-80"
			viewBox="0 0 20 20"
			fill="white"
		>
			<path
				fill-rule="evenodd"
				d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 3.582"
				clip-rule="evenodd"
			/>
		</svg>
		<div class="absolute centreInContext text-xs font-bold text-[#1982FC]">?</div>
	</div>
	{#if hovering && ($Version?.platform == 'darwin' || $Platform == 'macos')}
		<div class="top-0 left-3/4 pointer-events-none pl-6 absolute flex">
			<a
				transition:fade
				class="p-1.5 rounded-full pointer-events-auto relative hover:bg-blue-300"
				on:click|stopPropagation
				style="background:#1962cCdd;"
				href="{'mailto:mike@' +
					($Version.app === 'omnipilot' ? 'omnipilot.ai' : 'magicflow.com')}?&body={encodeURI(
					`Hey Mike, it's ${
						($UserEmail || '')?.replace('@', ' @ ') || 'a new user'
					}, I have feedback on Magicflow!&subject=Magicflow help request from ${
						($UserEmail || '')?.replace('@', ' @ ') + ' - ' + dayjs().format('YYYY-MM-DD')
					}`
				)}">Email</a
			>
		</div>
		<div
			class="top-0 pointer-events-none left-0 w-full h-full absolute flex items-center justify-center z-1000"
		>
			<div
				transition:fade
				class="p-1.5 rounded-full relative hover:bg-blue-300"
				on:click|stopPropagation|preventDefault
				style="background:#1962cC;"
			>
				iMessage
			</div>
		</div>
	{/if}
</a>
