<script>
	import {
		UserEmail,
		Version,
		IsDev,
		IsElectron,
		Platform,
		DemoMode,
		Loading
	} from '$lib/store.js';
	import { goto } from '$app/navigation';
	import { Path } from '$lib/store.js';

	import { page } from '$app/stores';
	import { browser } from '$app/environment';
	import NotificationScheduler from '../components/NotificationScheduler.svelte';
	import ContactMike from '../components/ContactMike.svelte';

	import Settings from './Settings.svelte';

	import Switch from '../components/Switch.svelte';
	let showScheduler;

	let menuShow = false;
	export let show;

	function toggleNavbar() {
		menuShow = !menuShow;
	}
	let breadcrumbs = ['Home'];
	$: breadcrumbs = [
		'Home',
		...$Path
			.slice(1)
			.split('/')
			.filter((s) => s && !s.includes('profile'))
	];
	let shifting;

	let hoverProfile, hovering, hoverTimeout;
</script>

<div
	class="fixed w-full {show
		? 'top-0'
		: '-top-36'} left-0 flex items-center justify-between p-1 pb-0"
	style="-webkit-app-region: drag;cursor:move;min-height:3rem;"
/>
<nav
	class="absolute {show
		? 'top-0'
		: '-top-36'} left-0 w-full flex z-10 items-center justify-between p-1 pb-0"
	style="-webkit-app-region: drag;pointer-events:none;cursor:move;min-height:3rem;top: calc(0.5rem + env(safe-area-inset-top, 0))"
>
	{#if (browser && ($page.url.pathname || '/') === '/') || $Path === '/'}
		<a
			sveltekit:prefetch
			on:mouseover|preventDefault={() => {
				hovering = true;
				hoverProfile = true;
				clearTimeout(hoverTimeout);
				hoverTimeout = undefined;
			}}
			on:mouseleave={() => {
				hovering = false;
				hoverTimeout = setTimeout(() => (hovering ? '' : (hoverProfile = false)), 200);
			}}
			href="/profile"
			class="relative cursor-pointer pointer-events-auto profile z-20
      p-3 m-1"
		>
			<div
				style="background: #222"
				class="relative text-black rounded-full border border-opacity-50 border-solid border-gray-600 flex items-center justify-center overflow-hidden"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					fill="currentColor"
					class="w-8 h-8 relative top-1"
				>
					<path
						fill-rule="evenodd"
						d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
						clip-rule="evenodd"
					/>
				</svg>
			</div>
			<div
				on:click|preventDefault
				on:mouseleave={() => {
					hovering = false;
					hoverTimeout = setTimeout(() => (hovering ? '' : (hoverProfile = false)), 200);
				}}
				on:mouseover|preventDefault={() => {
					clearTimeout(hoverTimeout);
					hoverTimeout = undefined;
					hovering = true;
					hoverProfile = true;
				}}
				class="w-96 absolute left-0 top-[75%] cursor-default {!hoverProfile
					? 'hoverProfile hidden'
					: ''} rounded-2xl"
				style="background: linear-gradient(
			153.72deg,
			#0a132a 3.26%,
			#102549 93%
		)"
			>
				<Settings dropdown={true} />
			</div>
		</a>
	{:else if $Path !== '/login' && $Path !== '/register' && browser && ($Path !== '/social' || $UserEmail)}
		<a
			class="cursor-pointer pointer-events-auto p-4 capitalize text-white flex items-center gap-1 text-xs
			{$Path.includes('/onboard') ? 'text-white relative z-50' : 'text-gray-300'} {$Path ===
				'/onboard/sync' || $Path === '/onboard'
				? 'invisible'
				: ''}"
			on:click|preventDefault={() => {
				if ($Path.startsWith('/onboard'))
					history?.length - 1
						? history.back()
						: goto(`/${breadcrumbs.slice(1, breadcrumbs?.length - 1).join('/')}`);
				else goto(`/${breadcrumbs.slice(1, breadcrumbs?.length - 1).join('/')}`);
			}}
			href="/{breadcrumbs.slice(1, breadcrumbs?.length - 1).join('/')}"
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				class="h-6
				w-6"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
					d="M7 16l-4-4m0 0l4-4m-4 4h18"
				/>
			</svg>
			{#if !$Path.startsWith('/onboard')}
				{(breadcrumbs[breadcrumbs?.length - 2] || '').replace(/-/g, ' ')}
			{/if}
		</a>
	{:else if $Path === '/social' && !$UserEmail}
		<button class="px-3 pointer-events-auto py-1 m-3" on:click={() => goto('/register')}
			>Sign up</button
		>
	{/if}
	<div class="relative pointer-events-auto justify-self-end">
		<!-- {#if $Path === '/'}
			<button
				class="absolute centreInContext cursor-pointer bg-transparent shadow-none text-gray-400 p-3"
				style="left:unset;right:50%"
				on:click={() => ($IsNative ? (showScheduler = true) : window.location.reload())}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					class="h-6 w-6"
					viewBox="0 0 20 20"
					fill="currentColor"
				>
					{#if $IsNative}
						<path
							d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"
						/>
					{:else}
						<path
							fill-rule="evenodd"
							d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
							clip-rule="evenodd"
						/>
					{/if}
				</svg>
			</button>
		{/if} -->
		<ContactMike />
	</div>
	{#if $DemoMode}
		<div class="DemoModeBannerContainer">
			<div class="DemoModeBanner DemoModeBannerLine">
				<div class="DemoModeBannerContent">
					<div>DEMO DATA</div>
				</div>
			</div>
		</div>
	{/if}
</nav>
{#if showScheduler}
	<NotificationScheduler bind:showScheduler />
{/if}

<style lang="postcss">
	.DemoModeBannerContainer {
		bottom: -1px;
		z-index: 1;
		opacity: 90%;
		position: absolute;
		height: 0px;
		max-height: 0px;
		width: 100%;
		left: 50%;
		transform: translateX(-50%);
	}
	.DemoModeBannerLine {
		border-radius: 0;
	}
	.DemoModeBanner {
		position: absolute;
		left: 0;
		right: 0;
		z-index: 6;
		border-top: 1px solid #ff0073;
		border-radius: 2px 2px 0 0;
		text-align: center;
		line-height: 0;
		pointer-events: none;
		transform: scaleY(-1) translateY(100%);
	}
	.DemoModeBannerContent div {
		transform: scaleY(-1);
	}
	.DemoModeBannerContent {
		position: relative;
		box-sizing: border-box;
		display: inline-block;
		height: 14px;
		padding: 2px;
		background-color: #ff0073;
		color: #fff;
		font-size: 10px;
		line-height: 10px;
		font-weight: 600;
	}
	.DemoModeBannerContent:before {
		content: '';
		position: absolute;
		top: 0;
		left: -8px;
		right: -8px;
		height: 100%;
		z-index: 0;
		pointer-events: none;
		background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjE0IiB3aWR0aD0iOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNi41MDQgMTRINS4wMDdBMy4wMDQgMy4wMDQgMCAwMTIgMTFWMi4wMUEyLjAwOSAyLjAwOSAwIDAwLS4wMDYgMGgtLjk5N0g4djE0eiIgZmlsbD0iI0ZGMDA3MyIgZmlsbC1ydWxlPSJldmVub2RkIj48L3BhdGg+PC9zdmc+'),
			url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjE0IiB3aWR0aD0iOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMS40OTMgMTRIMi45OWEzLjAwNCAzLjAwNCAwIDAwMy4wMDctM1YyLjAxQTIuMDEgMi4wMSAwIDAxOC4wMDMgMEg5LS4wMDN2MTR6IiBmaWxsPSIjRkYwMDczIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjwvcGF0aD48L3N2Zz4=');
		background-repeat: no-repeat;
		background-position:
			0 0,
			100% 0;
		background-size: 10px 14px;
	}
	.profile:hover > .hoverProfile.hidden {
		display: block;
	}
</style>
