<script>
	// import DataList from '../../../sections/DataList.svelte';
	import Modal from '../components/Modal.svelte';
	import LoadingDots from '../components/LoadingDots.svelte';
	import {
		DataUploaded,
		GraphsCached,
		MetaData,
		IsNative,
		IsElectron,
		Version
	} from '$lib/store.js';
	import { getAllMetadata, getData, deleteData, rePreProcess } from '$lib/utils.js';
	import { sync } from '$lib/connectUtils.js';
	import { goto } from '$app/navigation';

	import { sources } from '$lib/graphLists.js';
	import { Path } from '$lib/store.js';

	let deleting = false;
	let thingToPreProcess = 'messagebarchartrace';
	let show = 'hub';
	let showFile = '';
	async function deleteDataHandler(source, dataToDelete) {
		if (confirm(`Are you sure you want to delete ${dataToDelete}?`)) {
			let params = {
				confirmation: 'yes'
			};
			if (!dataToDelete) {
				alert(
					"Oops, that's a bug. Contact mike@{" +
						($Version.app === 'omnipilot' ? 'omnipilot.ai' : 'magicflow.com') +
						'} for support!'
				);
				return;
			}
			deleting = true;
			if (dataToDelete === 'all your data') {
				await deleteData().then((r) => {
					$DataUploaded = {};
					$MetaData = {};
					deleting = false;
				});
			} else if (dataToDelete === 'this entire dataset') {
				await deleteData(source);
				let dataUploaded = $DataUploaded;
				let metaData = $MetaData;

				delete dataUploaded[source];
				delete metaData[source];

				$DataUploaded = dataUploaded;
				$MetaData = metaData;
				show = 'hub';
			} else {
				await deleteData(source, dataToDelete);
				$DataUploaded[source] = $DataUploaded[source].filter(
					(dataName) => dataName != dataToDelete
				);
				delete $MetaData[source]?.single[dataToDelete];
			}
			localStorage.dataUploaded = Object.keys($DataUploaded).length
				? JSON.stringify($DataUploaded)
				: '';
			localStorage.metadata = Object.keys($MetaData).length ? JSON.stringify($MetaData) : '';
			deleting = false;
		} else
			alert(
				"Ok, you can do so any time. If you want to, you can let us know why you're thinking about deleting!"
			);
	}
	let dataLoaded = {};
</script>

{#if $Path.startsWith('/profile/data-choices')}
	<section class="relative w-full min-h-full p-6 m-0">
		<h1>Your Data Hub</h1>
		{#if $DataUploaded && Object.keys($DataUploaded).length === 0}
			<p class="text-center pt-8">Looks like you haven't got any data uploaded!</p>
			<a on:click|preventDefault={() => goto('upload')} href="/upload">
				<button class="flip">Upload</button>
			</a>
		{/if}
		{#if $DataUploaded && (show == 'hub' || show == 'ethi')}
			<p>The data you've uploaded:</p>
			<div class="grid grid-cols-2 gap-6">
				{#each sources.sort((sourceA, sourceB) => {
					let metaDetailsA = Object.values(($MetaData || {})[sourceA] || {})
						.map((typeOfDataForSource) => Object.values(typeOfDataForSource))
						.flat();
					let metaDetailsB = Object.values(($MetaData || {})[sourceB] || {})
						.map((typeOfDataForSource) => Object.values(typeOfDataForSource))
						.flat();
					if (metaDetailsA?.length && metaDetailsB?.length) {
						return Math.ceil((metaDetailsB
									.map((file) => file?.length)
									.reduce((a, b) => a + b) / (1024 * 1024)) * 100) / 100 - Math.ceil((metaDetailsA
									.map((file) => file?.length)
									.reduce((a, b) => a + b) / (1024 * 1024)) * 100) / 100;
					} else {
						let fileNamesA = $DataUploaded[sourceA] || [];
						let fileNamesB = $DataUploaded[sourceB] || [];
						return fileNamesB?.length - fileNamesA?.length;
					}
				}) as source}
					<card
						class="h-48"
						style={($DataUploaded[source] || []).length ? '' : 'filter: invert(0.03)'}
					>
						<div
							class={'flex items-center'}
							style={($DataUploaded[source] || []).length ? '' : 'filter: grayscale(1)'}
						>
							<img
								class="logo mr-2 w-6 h-6"
								src={`/logos/${source}_logo.svg`}
								alt={source + ' logo'}
							/>
							<p class="capitalize m-0"><b>{source}</b></p>
						</div>
						<div class="metric">
							{#if $MetaData && Object.keys($MetaData).length}
								{#if $MetaData[source]}
									{Math.ceil(
										([
											...Object.values($MetaData[source]?.single || []),
											...Object.values($MetaData[source]?.time_series || [])
										]
											.map((file) => file?.length)
											.reduce((a, b) => a + b) /
											(1024 * 1024)) *
											100
									) / 100}
								{:else}0{/if}
							{:else if $DataUploaded && Object.keys($DataUploaded).length}
								<LoadingDots />
							{:else}No data{/if}
							<span class="text-lg">MB</span>
						</div>
						<div class="metric">
							{(($DataUploaded || {})[source] || []).length}
							<span class="text-lg">FILES</span>
						</div>
						<div class="absolute flex flex-col right-8 bottom-4">
							<a on:click|preventDefault={() => goto('upload')} href="/upload">
								<button>
									{($DataUploaded[source] || []).length ? 'Update' : 'Upload'}
								</button>
							</a>
							{#if ($DataUploaded[source] || []).length}
								<button on:click={() => (show = source)}>Manage</button>
							{/if}
						</div>
					</card>
				{/each}
				<card class="h-48">
					<div style="display:flex;align-items:center;">
						<img class="logo mr-4 w-6 h-6" src={`/icons/favicon.webp`} alt={'Magicflow favicon'} />
						<p class="capitalize m-0"><b>Magicflow</b></p>
					</div>
					<div class="metric">
						{$GraphsCached && $GraphsCached?.length}
						<span style="font-size:0.7em">GRAPHS</span>
					</div>
					<div class="metric">
						{Object.keys($DataUploaded).length}
						<span style="font-size:0.7em">SOURCES</span>
					</div>
					<div class="absolute right-8 bottom-4">
						<button on:click={() => (show = 'ethi')}>Manage</button>
					</div>
					{#if show === 'ethi'}
						<Modal on:close={() => (show = 'hub')}>
							<p>
								Sometimes we update how we process your data, so your data needs re-processing.
								We'll be automating this, for now you'll sometimes need to click a button.
							</p>
							<select bind:value={thingToPreProcess}>
								{#each $GraphsCached as graph}
									<option value={graph}>{graph}</option>
								{/each}
							</select>
							<button
								on:click|preventDefault={(event) => {
									event.target.innerHTML = 'Refreshing!';
									rePreProcess(thingToPreProcess);
								}}
							>
								Refresh selected graph
							</button>
							<button
								on:click|preventDefault={(event) => {
									event.target.innerHTML = 'Refreshing!';
									rePreProcess();
								}}
							>
								Refresh all your graphs
							</button>
						</Modal>
					{/if}
				</card>
			</div>
			<button
				class="my-4"
				on:click|preventDefault={() => deleteDataHandler(undefined, 'all your data')}
				disabled={deleting || !$DataUploaded || $DataUploaded?.length === 0}
			>
				Delete All Data
			</button>
			{#if deleting}
				<p style="width:60%;text-align:left;">
					Deletion request sent and will execute in the background. It may take a few minutes to
					complete!
				</p>
			{/if}
		{:else}
			<!-- <ManageSource source={show} dataUploaded={$MetaData[show] || $DataUploaded[show]}/> -->
			<div class="relative w-full h-full">
				<card class="h-full w-full">
					<div
						class="flex gap-2 flex-wrap items-center justify-between pb-4 border-b-2 border-opacity-50
        border-gray-600 border-solid"
					>
						<button on:click={() => (show = 'hub')}>{'<-'}</button>
						<img class="logo mr-3 w-6 h-6" src={`/logos/${show}_logo.svg`} alt={show + ' logo'} />
						<p class="capitalize m-0"><b>{show}</b></p>
						<div class="metric">
							{#if $MetaData}
								{#if $MetaData[show]}
									{Math.ceil(
										(Object.values($MetaData[show]?.single || {})
											.map((file) => file?.length)
											.reduce((a, b) => a + b) /
											(1024 * 1024)) *
											100
									) / 100}
								{:else}0{/if}
							{:else}
								<LoadingDots />
							{/if}
							<span style="font-size: 0.6em;color: gray;">MB</span>
						</div>
						<div class="metric">
							{Object.keys(($DataUploaded || {})[show] || {}).length}
							<span style="font-size: 0.6em;color: gray;">FILES</span>
						</div>
						<a on:click|preventDefault={() => goto('upload')} href="/upload">
							<button>Update</button>
						</a>
						<button on:click={() => deleteDataHandler(show, 'this entire dataset')}>
							Delete All
						</button>
					</div>
					<div>
						{#each Object.entries((($MetaData || {})[show] || {}).single || {}) as [fileName, fileDetails]}
							<div
								class="grid gap-2 items-center p-2 border-b border-opacity-20
              border-gray-600 border-solid"
								style="grid-template-columns: 1fr 100px 350px 100px 100px;"
							>
								<div class="font-semibold break-all">{fileName}</div>
								<div class="font-semibold text-gray-100">
									{Math.round((fileDetails?.length / (1024 * 1024)) * 100) / 100}
									MB
								</div>
								<div class="text-sm text-gray-100 text-center">
									{new Date().toUTCString()}
								</div>
								<button
									on:click={() => {
										showFile = dataLoaded[fileName] || 'Loading data...';
										getData(fileName, show).then((fileData) => (showFile = fileData));
									}}
								>
									View
								</button>
								<button on:click={() => deleteDataHandler(show, fileName)}> Delete </button>
							</div>
						{/each}
						{#if showFile}
							<Modal on:close={() => (showFile = undefined)}>
								{JSON.stringify(showFile)}
							</Modal>
						{/if}
					</div>
				</card>
			</div>
		{/if}
		{#if $IsNative}
			<button
				on:click={() => {
					sync('apple', { hardResync: true });
				}}
			>
				Hard Resync Apple Health
			</button>
		{/if}
		{#if $IsElectron}
			<button
				on:click={() => {
					sync('whoop', { hardResync: true });
				}}
			>
				Hard Resync Whoop
			</button>
			<button
				on:click={() => {
					sync('activitywatch', { hardResync: true });
				}}
			>
				Resync Historical Screentime
			</button>
		{/if}
	</section>
{/if}

<style>
	.logo {
		margin-right: 20px;
		width: 40px;
		height: 40px;
	}
	card {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.metric {
		font-size: 1.8rem;
		font-weight: 600;
	}
	select {
		appearance: menulist;
		border-color: lightgrey;
		border-radius: 10px;
		padding: 0.5vh;
		color: #ff0073;
		font-weight: 600;
		text-align: center;
		margin: auto;
		width: 100%;
	}
</style>
