<script>
	import { checkUnique } from '$lib/utils.js';
	import { padTime } from '$lib/connectUtils.js';
	import Toast from './Toast.svelte';
	import { LocalNotifications } from '@capacitor/local-notifications';
	import { flip } from 'svelte/animate';
	import { quintOut } from 'svelte/easing';
	import { crossfade } from 'svelte/transition';

	export let showScheduler = undefined;
	const [send, receive] = crossfade({
		duration: (d, params) => Math.sqrt(d * 200),

		fallback(node, params) {
			const style = getComputedStyle(node);
			const transform = style.transform === 'none' ? '' : style.transform;

			return {
				duration: 600,
				easing: quintOut,
				css: (t) => `
					transform: ${transform} scale(${t});
					opacity: ${t}
				`
			};
		}
	});

	let schedule = ['11:00', '15:00', '19:00'];
	let focusOn;
	function focusInput(node, time) {
		if (time === focusOn) {
			node.focus();
			focusOn = undefined;
		}
	}
	function numericTime(time) {
		return time
			.split(':')
			.map((item, i) => (i ? Number(item) / 60 : Number(item)))
			.reduce((a, b) => a + b);
	}

	LocalNotifications.getPending()
		.then((notifObject) => {
			if (notifObject && notifObject.notifications && notifObject.notifications?.length) {
				let existingTrackNotifications = notifObject.notifications?.filter(
					(notification) => notification.title === 'Track your mood'
				);
				schedule = existingTrackNotifications
					.map((n) => padTime(n.schedule?.on?.hour) + ':' + padTime(n.schedule?.on?.minute))
					.filter(checkUnique);
			}
		})
		.catch((e) => console.error(e));

	let ethiCheck = (node, params) => {
		if (
			schedule?.findIndex((t) => t === params.key) === params.i ||
			schedule?.findIndex((t) => t === params.key) === -1
		)
			return;
		else return params.f(node, { key });
	};

	async function scheduleNotifications() {
		showScheduler = false;
		let times = schedule; // || ["11:00", "15:00", "19:00"];

		if (!(times && times?.length)) return;
		let pstate = await LocalNotifications.requestPermissions();
		// 'prompt' | 'prompt-with-rationale' | 'granted' | 'denied';;
		if (pstate.display === 'granted') {
			let existingNotifications = await LocalNotifications.getPending().then(
				(notifObject) => notifObject.notifications
			);
			let existingTrackNotifications = existingNotifications?.filter(
				(notification) => notification.title === 'Track your mood'
			);
			if (existingTrackNotifications && existingTrackNotifications?.length) {
				await LocalNotifications.cancel({
					notifications: existingTrackNotifications
				});
			}
			let notificationsScheduled = await LocalNotifications.schedule({
				notifications: times.map((time, i) => {
					return {
						id: 1000 * i + Math.round(999 * Math.random()),
						title: 'Track your mood',
						body: `It's ${time}, a great time to log how you're feeling`,
						schedule: {
							repeats: true,
							// at: dayjs()
							on: {
								hour: Number(time.split(':')[0]),
								minute: Number(time.split(':')[1])
							}
						}
					};
				})
			});
		}
	}
</script>

<Toast bind:showToast={showScheduler} onClose={() => (showScheduler = false)}>
	<div class="text-sm text-center mb-4 font-medium text-blue-100 opacity-90">NOTIFICATIONS</div>
	<div>
		Most people want reminders to track how they feel, it makes it easier not to forget! Set some
		times that work for you.
	</div>
	<button
		class="my-4"
		on:click={() => {
			focusOn = [
				'12:00',
				'10:00',
				'18:00',
				'09:00',
				'16:00',
				'07:00',
				'17:00',
				'08:00',
				'21:00',
				'06.00',
				'22:00',
				'05:00',
				'23:00',
				'04:00',
				'00:00'
			].find((time) => !schedule.includes(time));
			schedule = [...(schedule || []), focusOn]
				.sort((a, b) => numericTime(a) - numericTime(b))
				.filter(checkUnique);
		}}>Add New</button
	>
	{#each schedule as time, i (time)}
		<div
			class="flex justify-between items-stretch my-4 relative {true ? '' : 'existing'}"
			in:ethiCheck|local={{ key: time, i, f: receive }}
			out:ethiCheck|local={{ key: time, i, f: send }}
			animate:flip
		>
			<input
				class="bg-white p-2 text-2xl text-black w-2/3"
				style="filter:invert(1)"
				type="time"
				use:focusInput={time}
				value={time}
				on:change={(event) =>
					schedule.includes(event.target.value) && time !== event.target.value
						? event.target.parentElement.classList.add('existing')
						: event.target.parentElement.classList.remove('existing')}
				on:blur={(focusEvent) => {
					let timeInput = focusEvent.target;
					let newTime = timeInput.value;

					if (schedule.includes(newTime) && time !== newTime) {
						focusOn = time;
						focusInput(timeInput, time);
					} else {
						time = newTime;
						schedule = schedule.sort((a, b) => numericTime(a) - numericTime(b));
					}
				}}
			/>
			<button
				class="bg-black bg-opacity-50"
				on:click={() =>
					(schedule = schedule?.filter((timeToRemove, index) => i !== index)).filter(checkUnique)}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					class="h-5 w-5"
					viewBox="0 0 20 20"
					fill="currentColor"
				>
					<path
						fill-rule="evenodd"
						d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
						clip-rule="evenodd"
					/>
				</svg>
			</button>
		</div>
	{/each}
	<button class="flip" on:click={scheduleNotifications}> Schedule notifications </button>
</Toast>

<style lang="postcss">
	div.existing {
		@apply ring-1 ring-red-900;
	}
	div.existing:before {
		content: 'Two notifications at the same time.';
		@apply absolute top-full left-0 p-2 bg-red-900 z-50 text-xs;
	}
</style>
